<template>
  <aheader/>
    <div class="title mt-5 mb-0">
      <p>
        アクセス案内
      </p>
    </div>
    <section class="section">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <section class="hero is-small has-background-grey-lighter">
            <div class="hero-body">
              <p class="is-size-5 has-text-left">
                店舗情報
              </p>
            </div>
          </section>
          <div class="column is-11">
            <p class="has-text-left is-size-6-desktop">
              〒535-0013
              <br>大阪府大阪市旭区森小路２丁目９－２
              <br>TEL <a href="tel:066-954-1515">06-6954-1515</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <section class="hero is-small has-background-grey-lighter">
            <div class="hero-body">
              <p class="is-size-5 has-text-left m-0">
                電車でお越しの方へ
              </p>
            </div>
          </section>
          <div class="column is-11">
            <div class="block">
              <p class="title is-size-6 has-text-left is-underlined mt-2">
                大阪メトロ谷町線 千林大宮駅 を利用される場合（徒歩3分）
              </p>
              <ul class="content has-text-left is-size-6-desktop ml-3">
                <li>改札を出て右方向、①番出口へ。</li>
                <li>階段をのぼり、千林商店街を進みます。</li>
                <li>約100mほど先の突き当たりを右折。</li>
                <li>商店街を抜けて、100mほどで左側にお店がみえます。</li>
              </ul>
            </div>
            <div class="block mt-3">
              <p class="title is-size-6 is-underlined has-text-left">
                京阪電鉄 千林駅 を利用される場合（徒歩5分）
              </p>
              <ul class="content has-text-left is-size-6-desktop ml-3">
                <li>改札を出て、商店街を左斜め方向にまっすぐ進みます。</li>
                <li>突き当たりまで進んだところで左に曲がります。</li>
                <li>そのまま商店街を抜けて、100mほどで左側にお店がみえます。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <section class="hero is-small has-background-grey-lighter">
            <div class="hero-body">
              <p class="is-size-5 has-text-left">
                車でお越しの方へ
              </p>
            </div>
          </section>
          <div class="column is-11">
            <p class="has-text-left is-size-6-desktop">
              車でお越しの際は、近隣のコインパーキングをご利用ください。
              <br>（店舗まで徒歩１～２分圏内に 箇所ございます。）
            </p>
            <p class="has-text-left is-size-6-desktop mt-3">
              ※１５時～１９時までの間は、商店街内の車両の通行が規制されております。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <section class="hero is-small has-background-grey-lighter">
            <div class="hero-body">
              <p class="is-size-5 has-text-left">
                周辺地図
              </p>
            </div>
          </section>
          <div class="m-5">
            <div class="image is-16by9">
              <iframe class="has-ratio" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.278951669704!2d135.54871371523296!3d34.72336238042862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e1131c115555%3A0x74a146414a798b4d!2z44GK44GQ44KJ5bGL!5e0!3m2!1sja!2sjp!4v1647776754393!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  <afooter/>
</template>
<script>
export default {
  mounted() {
    this.scrollTop()
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
<style>
</style>
